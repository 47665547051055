import React from 'react';
import styled from 'styled-components';
import { Box } from '../../designsystem/Box';

export interface InputProps {
  name: string;
  label: string;
  type?: string;
  value: string;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

const Label = styled.label`
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.02em;
`;

const InputElement = styled.input`
  border: solid 1px rgba(0, 0, 0, 0.6);
  width: 100%;
  border-radius: 5px;
  padding: 0.5rem 0.75rem;
  transition: box-shadow 0.3s;
  outline: none;

  &:focus {
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.3);
  }
`;

export const Input: React.FC<InputProps> = ({
  name,
  label,
  type,
  value,
  onChange,
}) => {
  return (
    <Box mb={3} width="100%">
      <Box mb={1}>
        <Label htmlFor={label}>{label}</Label>
      </Box>
      <Box>
        <InputElement
          name={name}
          id={label}
          type={type || 'text'}
          value={value}
          onChange={onChange}
        />
      </Box>
    </Box>
  );
};
