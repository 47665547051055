import React from 'react';
import styled from 'styled-components';
import { Box } from '../../designsystem/Box';
import { ReactComponent as ChevronIcon } from '../../images/chevron-down.svg';

export interface Option {
  value: string;
  label?: string;
}
export interface SelectProps {
  name: string;
  label: string;
  options: Option[];
  onChange: (value: string) => void;
  value: string;
}

const Label = styled.label`
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.02em;
`;

const SelectLabel = styled.label`
  background-color: #fff;
  display: block;
  color: #000;
  border: none;
  width: 100%;
  border-radius: 5px;
  padding: 0.5rem 0.75rem;
  transition: box-shadow 0.3s;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);

  select:focus + & {
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.3);
  }
`;

const SelectElement = styled.select`
  position: relative;
  z-index: 1;
  opacity: 0;
  width: 100%;
  padding: 0.5rem 0.75rem;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  svg {
    stroke: #000;
    vertical-align: top;
  }
`;

export const Select: React.FC<SelectProps> = ({
  name,
  options,
  label,
  onChange,
  value,
}) => {
  const current = options.find(item => item.value === value);
  return (
    <Box position="relative">
      <Box mb={1}>
        <Label htmlFor={name}>{label}</Label>
      </Box>
      <Box position="relative">
        <SelectElement
          id={name}
          name={name}
          onChange={ev => onChange(ev.target.value)}
          value={value}
        >
          {options.map(({ label, value }) => (
            <option key={value} value={value}>
              {label || value}
            </option>
          ))}
        </SelectElement>
        <SelectLabel htmlFor={name}>
          {current ? current.label || current.value : value}
        </SelectLabel>
        <IconWrapper>
          <ChevronIcon />
        </IconWrapper>
      </Box>
    </Box>
  );
};
