import React, { useState } from 'react';
import styled from 'styled-components';
import { Box } from '../../designsystem/Box';
import { Button } from '../../designsystem/Button';
import { useForm } from '../../utils/useForm';
import { Checkbox } from './Checkbox';
import { Input } from './Input';
import { Select } from './Select';

interface UnitedFormProps {
  id: string;
  name: string;
  teams: Array<{ team_name: string }>;
  objectTypes: Array<{ name: string }>;
}

const Wrapper = styled(Box)`
  color: #000;
`;

const InnerWrapper = styled(Box)`
  @media screen and (min-width: 40em) {
    border-bottom: solid 1px #ccc;
    border-top: solid 1px #ccc;
  }
`;

const LoadingBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 71, 101, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2.5rem;
  padding: 0 1.5rem;

  @media screen and (min-width: 30em) {
    font-size: 3rem;
  }

  @media screen and (min-width: 50em) {
    font-size: 4rem;
  }
`;

const SuccessBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #004765;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2.5rem;
  padding: 0 1.5rem;

  @media screen and (min-width: 30em) {
    font-size: 3rem;
  }

  @media screen and (min-width: 50em) {
    font-size: 4rem;
  }
`;

const validate = ({
  tipsterName,
  tipsterEmail,
  tipsterPhoneNumber,
  sellerName,
  sellerEmail,
  sellerPhoneNumber,
  approvedGDPR,
}: {
  tipsterName: string;
  tipsterEmail: string;
  tipsterPhoneNumber: string;
  sellerName: string;
  sellerEmail: string;
  sellerPhoneNumber: string;
  approvedGDPR: boolean;
}): boolean => {
  return approvedGDPR;
};

const encode = (data: any) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export const UnitedForm: React.FC<UnitedFormProps> = ({
  id,
  name,
  teams,
  objectTypes,
}) => {
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const formAction = process.env.GATSBY_FORM_URL || '';
  const { values, handleChange, handleSubmit, setValue } = useForm({
    initialValues: {
      tipsterName: '',
      tipsterEmail: '',
      tipsterPhoneNumber: '',
      sellerName: '',
      sellerEmail: '',
      sellerPhoneNumber: '',
      approvedGDPR: false,
      team: '',
      objectType: '',
      name: name,
      id: id,
    },
    onSubmit: data => {
      if (!validate(data)) return;

      setLoading(true);

      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'united', ...data }),
      })
        .then(res => console.log('Netlify success.'))
        .catch(res => console.log('Netlify failed.'));

      fetch(formAction, {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then(res => {
          setSent(true);
          setLoading(false);
        })
        .catch(err => {
          console.log('ERROR', err);
          setLoading(false);
        });
    },
  });

  const teamsWithDefault = [
    { value: '', label: 'Välj lag' },
    ...teams.map(({ team_name }) => ({ value: team_name })),
  ];

  const objectTypesWithDefault = [
    { value: '', label: 'Välj typ av objekt' },
    ...objectTypes.map(({ name }) => ({ value: name })),
  ];

  return (
    <Wrapper id="tipsa-har" position="relative" py={0}>
      <InnerWrapper maxWidth={800} mx="auto" pt={[0, 3]}>
        <form
          action={formAction}
          method="post"
          data-netlify="true"
          name="united"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="id" value={values.id} />
          <input type="hidden" name="name" value={values.name} />

          <Box
            display="flex"
            flexDirection={['column', 'row']}
            alignItems="flex-end"
            mt={2}
          >
            <Box mr={[0, 4]} flex="1" width="100%" px={4} pb={[3, 0]}>
              {teams && teams.length > 0 && (
                <Box mb={4}>
                  <Select
                    name="team"
                    label="Lag"
                    options={teamsWithDefault}
                    value={values.team}
                    onChange={value => setValue('team', value)}
                  />
                </Box>
              )}

              <Box style={{ fontSize: '1rem' }} mb={2}>
                Jag vill tipsa om följande person som ska sälja:
              </Box>
              <Box mb={3}>
                <Select
                  name="objectType"
                  label="Objektstyp"
                  options={objectTypesWithDefault}
                  value={values.objectType}
                  onChange={value => setValue('objectType', value)}
                />
              </Box>
              <Input
                name="sellerName"
                label="Säljarens namn"
                type="text"
                value={values.sellerName}
                onChange={handleChange('sellerName')}
              />
              <Input
                name="sellerPhoneNumber"
                label="Säljarens telefon"
                type="text"
                value={values.sellerPhoneNumber}
                onChange={handleChange('sellerPhoneNumber')}
              />
              <Input
                name="sellerEmail"
                label="Säljarens epost"
                type="email"
                value={values.sellerEmail}
                onChange={handleChange('sellerEmail')}
              />
            </Box>

            <Box
              flex="1"
              width="100%"
              bg={['#f1f1f1', '#fff']}
              px={4}
              pt={[3, 0]}
            >
              <Box style={{ fontSize: '1rem' }} mb={2}>
                Jag som tipsar är:
              </Box>

              <Input
                name="tipsterName"
                label="Ditt namn"
                type="text"
                value={values.tipsterName}
                onChange={handleChange('tipsterName')}
              />

              <Input
                name="tipsterPhoneNumber"
                label="Din telefon"
                type="text"
                value={values.tipsterPhoneNumber}
                onChange={handleChange('tipsterPhoneNumber')}
              />

              <Input
                name="tipsterEmail"
                label="Din epost"
                type="email"
                value={values.tipsterEmail}
                onChange={handleChange('tipsterEmail')}
              />
            </Box>
          </Box>

          <Box py={3} px={4} bg={['#f1f1f1', '#fff']}>
            <Box mb={3}>
              <Checkbox
                id="approvedGDPR"
                label={
                  <div>
                    Genom att markera denna ruta godkänner jag Hoome Mäkleris{' '}
                    <a href="/personuppgiftspolicy" target="_blank">
                      personuppgiftspolicy
                    </a>
                    ,{' '}
                    <a href="/villkor" target="_blank">
                      villkoren
                    </a>{' '}
                    för tjänsten och försäkrar även att den person tipset avser
                    gett sitt samtycke att dela dennes personuppgifter och bli
                    kontaktad av Hoome Mäkleri.*
                  </div>
                }
                value={values.approvedGDPR}
                onChange={handleChange('approvedGDPR')}
              />
            </Box>

            <Box display="flex" justifyContent={'center'} mt={4}>
              <Button type="submit" disabled={!validate(values)}>
                Skicka in tips
              </Button>
            </Box>
          </Box>
        </form>
      </InnerWrapper>
      {loading && <LoadingBox>Laddar...</LoadingBox>}
      {sent && <SuccessBox>Tack för ditt tips!</SuccessBox>}
    </Wrapper>
  );
};
