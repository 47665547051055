import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styled, { DefaultTheme, ThemeProvider } from 'styled-components';
import { BlockArea } from '../components/blocks/BlockArea';
import Footer from '../components/Footer';
import { UnitedForm } from '../components/forms/UnitedForm';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Box } from '../designsystem/Box';
import { Logo } from '../designsystem/Logo';
import { ReactComponent as UnitedLogo } from '../images/hoome-united-logo.svg';
import { defaultTheme } from '../theme';
import { isNotNull } from '../utils/isNotNull';
import { UnitedPageQuery } from '../__generated__/graphql';

const UnitedPage: React.FC<{
  data: UnitedPageQuery;
  pageContext: { unitedSettings: any };
}> = ({ data, pageContext }) => {
  const id = data.prismicUnitedpage!.prismicId;
  const page = data.prismicUnitedpage!.data!;
  const logo = page.logo!.localFile
    ? page.logo!.localFile.childImageSharp!.fluid!
    : null;
  const settings = data.prismicUnitedsettings!.data!;
  const name = page.name!;

  const headerImage = page.header_image!.localFile
    ? (page.header_image!.localFile.childImageSharp!.fluid as object)
    : null;
  const blocks = (settings.body || []).filter(isNotNull);
  const teams = (page.teams || []).filter(isNotNull);
  const objectTypes = (settings.object_types || []).filter(isNotNull);

  const headerText = page.header_text;
  const defaultHeaderText = settings.default_header_text;

  const buttonColors = [page.theme_color_1, page.theme_color_2];

  const theme: DefaultTheme = {
    ...defaultTheme,
    colors: {
      ...(defaultTheme.colors || {}),
      color1: buttonColors[0] || defaultTheme.colors.color1,
      color2: buttonColors[1] || defaultTheme.colors.color2,
    },
  };

  return (
    <Layout>
      <ThemeProvider theme={theme}>
        <>
          <SEO
            title={page.name!}
            description={page.description!.text!}
            disallow
          />

          <UnitedHeader>
            <HeaderImage>
              {headerImage && <Img fluid={headerImage as any} critical />}
            </HeaderImage>
            <HeaderContentOuter>
              <HeaderContent>
                {logo != null ? (
                  <Img
                    fluid={logo as any}
                    style={{ width: '80px', height: '80px' }}
                    critical
                  />
                ) : null}
                <h1>{headerText || defaultHeaderText}</h1>
              </HeaderContent>
            </HeaderContentOuter>
            <HeaderLogoWrapper>
              <UnitedLogo />
            </HeaderLogoWrapper>
          </UnitedHeader>

          <Box mb={4}>
            <Box mb={4} mx="auto" px={4} maxWidth={800}>
              <div
                dangerouslySetInnerHTML={{
                  __html: page.description!.html || '',
                }}
              />
            </Box>

            <UnitedForm
              id={id!}
              name={name}
              teams={teams as any}
              objectTypes={objectTypes as any}
            />
          </Box>

          <a id="bakgrund" />
          <BlockArea blocks={blocks} />
          <Box maxWidth="250px" width="80%" my={5} mx="auto">
            <Logo />
          </Box>
          <Footer />
        </>
      </ThemeProvider>
    </Layout>
  );
};

export const query = graphql`
  query UnitedPageQuery($id: String) {
    prismicUnitedpage(prismicId: { eq: $id }) {
      id
      prismicId
      data {
        name
        slug
        header_text
        description {
          html
          text
        }
        theme_color_1
        theme_color_2
        teams {
          team_name
        }

        header_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1200) {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcWebp
                srcSetWebp
              }
            }
          }
        }

        logo {
          localFile {
            childImageSharp {
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
    }
    prismicUnitedsettings {
      id
      data {
        default_header_text
        object_types {
          name
        }
        body {
          __typename

          ... on Node {
            id
          }

          ... on PrismicUnitedsettingsBodyText {
            primary {
              text {
                html
              }
            }
          }

          ...ButtonSlice

          ...ImageSlice
        }
      }
    }
  }

  fragment ButtonSlice on PrismicUnitedsettingsBodyButton {
    button: primary {
      link
      text
    }
  }

  fragment ImageSlice on PrismicUnitedsettingsBodyImage {
    primary {
      width
      image {
        localFile {
          childImageSharp {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
      }
    }
  }
`;

const UnitedHeader = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 60%;

  @media screen and (min-width: 30em) {
    padding-bottom: 40%;
  }
`;
const HeaderImage = styled.div`
  .gatsby-image-wrapper {
    position: absolute !important;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-50%);

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
`;
const HeaderLogoWrapper = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 5rem;
  height: 5rem;
`;
const HeaderContent = styled.div`
  color: #fff;
  display: flex;
  align-items: center;

  h1 {
    margin-left: 2rem;
    margin-bottom: 0;
    font-size: 1.75rem;
    font-weight: 700;

    @media screen and (min-width: 40em) {
      font-size: 2.25rem;
    }

    @media screen and (min-width: 70em) {
      font-size: 3.5rem;
    }
  }

  .gatsby-image-wrapper {
    flex-shrink: 0;
    width: 80px !important;
    height: 80px !important;

    @media screen and (min-width: 40em) {
      width: 100px !important;
      height: 100px !important;
    }

    @media screen and (min-width: 70em) {
      width: 120px !important;
      height: 120px !important;
    }
  }
`;

const HeaderContentOuter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));

  /* @media screen and (min-width: 40em) {
    top: 0;
    background-image: none;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  } */
`;

export default UnitedPage;
