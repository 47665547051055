import produce from 'immer';
import { Reducer, useReducer } from 'react';

interface Action {
  name: string | number | symbol;
  value: any;
}

export const useForm = <T extends { [key: string]: any }>(options: {
  initialValues: T;
  onSubmit: (data: T) => void;
}) => {
  const [values, setState] = useReducer<Reducer<T, Action>>((state, action) => {
    return produce(state, draft => {
      if (typeof action.name === 'symbol') return;
      draft[action.name] = action.value;
    });
  }, options.initialValues);

  const handleChange = (name: keyof T) => (
    ev: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value =
      ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value;

    setState({ name, value });
  };

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    options.onSubmit(values);
  };

  const setValue = <K extends keyof T>(name: K, value: T[K]) => {
    setState({ name, value });
  };

  return { values, handleChange, handleSubmit, setValue };
};
