import React from 'react';
import styled from 'styled-components';
import { Box } from '../../designsystem/Box';
import { ReactComponent as CheckIcon } from '../../images/check.svg';

export interface CheckboxProps {
  id: string;
  label: React.ReactNode;
  value: boolean;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

const Label = styled.label`
  font-size: 0.875rem;
  font-weight: bold;
  div {
    padding-left: 2.5rem;
  }
`;

const Check = styled.div`
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #fff;
  border: solid 1px #000;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border-radius: 5px;
  transition: box-shadow 0.3s;
  box-shadow: 0 0 0 3px transparent;
  pointer-events: none;

  svg {
    opacity: 1;
    stroke: #000;
    path {
      stroke-dasharray: 22.627416610717773;
      stroke-dashoffset: 22.627416610717773;
      transition: stroke-dashoffset 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
  }

  input:focus + & {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.3);
  }

  input:checked + & {
    svg {
      opacity: 1;
      path {
        stroke-dashoffset: 0;
      }
    }
  }
`;

const InputElement = styled.input`
  border: none;
  border: solid 1px #000;
  width: 2rem;
  opacity: 0;
  position: absolute;
`;

export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  label,
  value,
  onChange,
}) => {
  return (
    <Box position="relative" display="flex">
      <InputElement
        id={id}
        type="checkbox"
        checked={value}
        onChange={onChange}
      />
      <Check>
        <CheckIcon />
      </Check>
      <Label htmlFor={id}>{label}</Label>
    </Box>
  );
};
