import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  fill: ${props => props.theme.colors.primary};
  width: 100%;
  height: auto;
`;

export class Logo extends React.PureComponent {
  render() {
    return (
      <Svg
        height="137"
        viewBox="0 0 436 137"
        width="436"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <g transform="translate(86 96)">
            <path d="m5.418 10.628h7.72l6.12 16.119 6.48-16.119h7.799l4.561 30.16h-7.84l-2.2-17.36h-.08l-7.24 17.36h-3.12l-6.92-17.36h-.08l-2.52 17.36h-7.8z" />
            <path d="m77.534 4.267c0 2.201-1.76 3.921-3.92 3.921-2.2 0-3.96-1.72-3.96-3.921 0-2.159 1.76-3.919 3.96-3.919 2.16 0 3.92 1.76 3.92 3.919zm-8.96 15.681h-.08l-3.24 9.6h6.52zm-1.28-15.681c0 2.201-1.76 3.921-3.92 3.921-2.2 0-3.96-1.72-3.96-3.921 0-2.159 1.76-3.919 3.96-3.919 2.16 0 3.92 1.76 3.92 3.919zm-4.2 31.281-2.08 5.24h-8.32l11.6-30.16h8.56l11.36 30.16h-8.36l-1.96-5.24z" />
            <path d="m118.051 10.628h9.719l-11.999 14.28 13.079 15.88h-10.16l-10.12-13.08h-.08v13.08h-7.839v-30.16h7.839v12.44h.08z" />
            <path d="m152.808 34.147h9.399v6.641h-17.24v-30.16h7.841z" />
            <path d="m186.606 17.267v5.041h8.839v6.64h-8.839v5.199h9.319v6.641h-17.16v-30.16h17.16v6.639z" />
            <path d="m222.603 24.148h.76c2.559 0 5.44-.48 5.44-3.76s-2.881-3.76-5.44-3.76h-.76zm17.24 16.64h-9.761l-7.4-11.6h-.079v11.6h-7.84v-30.16h11.72c5.96 0 10.479 2.84 10.479 9.28 0 4.16-2.319 7.76-6.599 8.52z" />
            <path d="m255.959 40.788h7.841v-30.16h-7.841z" />
          </g>
          <path d="m48.017 43.772-12.116-5.249-11.754 5.249v15.264l11.754-5.25 12.116 5.25v19.054h19.426v-74.73h-19.426z" />
          <path d="m19.969 43.772v-40.412h-19.426v74.73h19.426v-19.054" />
          <path d="m127.607 61.737c-11.596 0-21.111-9.416-21.111-22.301 0-10.307 9.515-19.723 21.111-19.723s21.111 9.416 21.111 19.723c0 12.885-9.515 22.301-21.111 22.301zm0-60.855c-21.507 0-41.329 15.858-41.329 38.455 0 24.183 17.344 41.231 41.329 41.231 23.984 0 41.329-17.048 41.329-41.231 0-22.597-19.822-38.455-41.329-38.455zm97.826 60.855c-11.596 0-21.111-9.416-21.111-22.301 0-10.307 9.515-19.723 21.111-19.723s21.11 9.416 21.11 19.723c0 12.885-9.514 22.301-21.11 22.301zm0-60.855c-21.507 0-41.329 15.858-41.329 38.455 0 24.183 17.344 41.231 41.329 41.231 23.984 0 41.329-17.048 41.329-41.231 0-22.597-19.821-38.455-41.329-38.455z" />
          <path d="m344.271 3.36-16.057 39.942-15.163-39.942h-19.129l-12.685 74.73h19.326l6.244-43.015h.198l17.147 43.015h7.73l17.939-43.015h.199l5.45 43.015h19.427l-11.299-74.73z" />
          <path d="m435.555 19.812v-16.452h-42.519v74.73h42.519v-16.452h-23.093v-17.017h21.903v-16.453h-21.903v-8.356z" />
        </g>
      </Svg>
    );
  }
}
